import React from 'react';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import { logEvent } from '../../../analytics';
import {
  IAnimationProduct,
  ChopardAnimation,
  REMOTE_ACTION
} from '../../../interfaces/chopard';
import {
  actionUpdateChopardActiveAnimationInPano,
  actionUpdateChopardSelectedModel
} from '../../../redux/customActions/chopard';
import {
  DID_CLOSE_ANIMATION,
  DID_SELECT_ANIMATION_MODEL,
  DID_SHOW_ANIMATION
} from '../../../utils/constants';
import { useChopardState } from '../../hooks/chopard';
import PreventMobileZoomContainer from '../../PreventMobileZoomContainer';
import { Spinner } from 'react-bootstrap';
import { canInteractWithAnimation } from '../../hooks/meeting';
import { VscChromeClose } from 'react-icons/vsc';
import { MDLandscapeNormalSpec } from '../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../Meeting/MeetingLayout/SMLandscapeNormal';

export interface IAnimationContext {
  setSelectedModel: (selectedModel: IAnimationProduct) => void;
  setLoaded: (loaded: boolean) => void;
  onCloseAnimation: () => void;
  selectedModel: IAnimationProduct;
  activeAnimation: ChopardAnimation;
}

export const AnimationContext =
  React.createContext<IAnimationContext>(undefined);

export const FADE_SPEED = 1000;

const AnimationContextContainer = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const viewOnly = !canInteractWithAnimation();
  const [showAnimation, setShowAnimation] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const chopardState = useChopardState();
  const { activeAnimation } = chopardState;

  const setSelectedModel = (model: IAnimationProduct) => {
    dispatch(
      actionUpdateChopardSelectedModel(
        model ? { model, state: REMOTE_ACTION.FOCUS } : undefined
      )
    );
  };
  const selectedModel =
    chopardState.selectedModel?.state === REMOTE_ACTION.FOCUS
      ? chopardState.selectedModel?.model
      : undefined;

  React.useEffect(() => {
    if (selectedModel) {
      logEvent(DID_SELECT_ANIMATION_MODEL, DID_SELECT_ANIMATION_MODEL, {
        animation: activeAnimation,
        selectedModel
      });
    }
  }, [selectedModel]);

  React.useEffect(() => {
    if (activeAnimation) {
      logEvent(DID_SHOW_ANIMATION, DID_SHOW_ANIMATION, {
        animation: activeAnimation
      });
    }
    setShowAnimation(!!activeAnimation);
  }, [activeAnimation]);

  const onCloseAnimation = () => {
    setSelectedModel(undefined);
    setShowAnimation(false);
    setTimeout(() => {
      dispatch(actionUpdateChopardActiveAnimationInPano(undefined));
    }, FADE_SPEED);
    logEvent(DID_CLOSE_ANIMATION, DID_CLOSE_ANIMATION, {
      animation: activeAnimation
    });
  };

  return (
    <AnimationContext.Provider
      value={{
        setSelectedModel,
        setLoaded,
        onCloseAnimation,
        selectedModel,
        activeAnimation
      }}
    >
      <PreventMobileZoomContainer>
        {!loaded && showAnimation && (
          <div className="loading">
            <Spinner animation="border" variant="light" />
            <style jsx>
              {`
                .loading {
                  position: fixed;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  z-index: 99;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                :global(.in-meeting.MDLandscape) .loading {
                  left: ${MDLandscapeNormalSpec.contentArea.left / 2}px;
                }
                :global(.in-meeting.SMLandscape) .loading {
                  left: ${SMLandscapeNormalSpec.contentArea.left / 2}px;
                }
              `}
            </style>
          </div>
        )}
        <Transition
          in={showAnimation}
          timeout={FADE_SPEED}
          mountOnEnter
          unmountOnExit
        >
          {(state) => (
            <div className={`chopard-animation ${state}`}>
              {children}

              {!viewOnly && (
                <button
                  className="btn close-button with-shadow"
                  onClick={onCloseAnimation}
                >
                  <VscChromeClose color="#fff" />
                </button>
              )}

              <style jsx>{`
                .chopard-animation {
                  height: 100%;
                  transition: opacity 1s ease-in-out;
                }

                .chopard-animation.entering {
                  opacity: 0;
                }
                .chopard-animation.entered {
                  opacity: 1;
                }
                .chopard-animation.exiting {
                  opacity: 0;
                }
                .chopard-animation.exited {
                  opacity: 0;
                }
                button {
                  position: fixed;
                  top: 15px;
                  right: 0;
                  outline: none !important;
                  box-shadow: none !important;
                }
                .close-button :global(svg) {
                  width: 30px;
                  height: auto;
                }
              `}</style>
              <style jsx global>{`
                .MD .chopard-animation .close-button {
                  top: ${MDLandscapeNormalSpec.contentArea.top +
                  15}px !important;
                }
                .MD .chopard-animation .close-button {
                  top: ${SMLandscapeNormalSpec.contentArea.top +
                  15}px !important;
                }
              `}</style>
            </div>
          )}
        </Transition>
      </PreventMobileZoomContainer>
    </AnimationContext.Provider>
  );
};

export default AnimationContextContainer;
