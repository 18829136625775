import { assetBaseUrl } from '../../../config';
export const animationAssetsBaseUrl = `${assetBaseUrl}/chopard/ww2021/animation/`;
// export const animationAssetsBaseUrl = 'http://192.168.1.12:3000/luggage-animation/chopard/';

const artisansResourcePath = animationAssetsBaseUrl + 'artisans/v1/resources/';
export const dialModel = artisansResourcePath + 'dial.glb';
export const mainStrap = artisansResourcePath + 'main_strap.glb';
export const artisansBackground = artisansResourcePath + 'Artisans.jpg';
export const interiorHDR = artisansResourcePath + 'interior.hdr';
 
const atriumResourcePath = animationAssetsBaseUrl + 'atrium/v1/resources/';
export const mainAtrium = atriumResourcePath+'MainAtrium.jpg';
export const stainlessStrap = atriumResourcePath+'stainlessStrap.glb';
export const mainStrapGLB = atriumResourcePath+'main_strap.glb';
export const leatherStrap = atriumResourcePath+'leatherStrap.glb';
export const dial = atriumResourcePath+'dial.glb';

const circusResourcePath = animationAssetsBaseUrl + 'circus/v1/resources/';
export const ringImg = circusResourcePath+'ring.png';
export const circusImg = circusResourcePath+'Circus.jpg';
export const stripeHoop = circusResourcePath+'stripe hoop.glb';
export const glowingHoop = circusResourcePath+'glowing hoop.glb';
export const clown = circusResourcePath+'clown.glb';
export const cakesteps = circusResourcePath+'cake steps.glb';