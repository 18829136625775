import React from 'react';
import * as THREE from 'three';
import * as CANNON from 'cannon';
import { addVisual, addBody, initPhysics, updateBodies } from './Common';
import { dialModel, mainStrap, mainAtrium, interiorHDR } from './assets';

import {
  IChopard3DAnimationState,
  REMOTE_ACTION
} from '../../../interfaces/chopard';
import { MDLandscapeNormalSpec } from '../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../Meeting/MeetingLayout/SMLandscapeNormal';
let artisanRemoteState = undefined;
const Artisan = ({
  remoteState,
  updateRemoteState,
  onReady,
  viewOnly
}: {
  remoteState?: IChopard3DAnimationState;
  updateRemoteState: (state: IChopard3DAnimationState) => void;
  onReady: () => void;
  viewOnly?: boolean;
}) => {
  let camera, GLTFLoader, RGBELoader, TWEEN, ObjectControls, renderer;
  let scene = new THREE.Scene();
  let controls = undefined;
  let isTweening = false;
  let focusedModel = undefined;
  let connonDial = undefined;
  let object3DDial = undefined;
  let object3DDialGlass = undefined;
  let glassCylender = undefined;
  let isDown = false;
  let loadingCount = 0;
  let selectedItem = -1;
  const lastGlassPos = new THREE.Vector3();
  const mouse = new THREE.Vector2();
  const raycaster = new THREE.Raycaster();
  const maxScale = 1;
  const minScale = 0.25;
  const incScale = 0.02;
  const itemsArray = Array(1);
  const itemsChildForCatchingClick = Array(1);
  const connonWorld = initPhysics(scene);
  const mCannonDimonds = [];
  const object3dDimonds = [];
  const NO_OF_DIMOND = 5;
  const STR_DIMOND = [
    'Object016',
    'Object019',
    'Object020',
    'Object021',
    'Object022'
  ];
  const STR_DIMOND_COVER = [
    'Cylinder037',
    'Cylinder041',
    'Cylinder042',
    'Cylinder043',
    'Cylinder044'
  ];

  const updateRemoteObjectControlForModel = () => {
    if (!focusedModel) return;
    updateRemoteState({
      flag: REMOTE_ACTION.MOUSE_CONTROL,
      focusItem: selectedItem,
      position: [
        focusedModel.position.x,
        focusedModel.position.y,
        focusedModel.position.z
      ],
      scale: [focusedModel.scale.x, focusedModel.scale.y, focusedModel.scale.z],
      rotation: [
        focusedModel.rotation.x,
        focusedModel.rotation.y,
        focusedModel.rotation.z
      ]
    });
  };

  const callback = () => {
    onReady();
    addConnonObject();
    loadingCount = 1;
  };
  const loadingManager = new THREE.LoadingManager(() => {
    callback();
  });
  const touchType = {
    touchDown: 'touchDown',
    touchMove: 'touchMove',
    touchUp: 'touchUp'
  };
  const addConnonObject = () => {
    const sphere = new CANNON.Sphere(0.08);
    for (let i = 0; i < NO_OF_DIMOND; i++) {
      mCannonDimonds.push(
        addBody(connonWorld, sphere, scene, 0, {
          x: -0.2 + i * 0.1,
          y: 3,
          z: 0
        })
      );
    }

    watch();
  };
  const watch = () => {
    let rads = Math.PI * 0.5;
    const material = new CANNON.Material();
    const base = new CANNON.Cylinder(2, 2, 0.1, 8);
    const box = new CANNON.Box(new CANNON.Vec3(1, 1, 0.12));
    connonDial = new CANNON.Body({ mass: 100, material: material });
    connonDial.addShape(base, new CANNON.Vec3(0, 0, 0.15));
    connonDial.addShape(base, new CANNON.Vec3(0, 0, -0.15));
    const no = 16;
    for (let i = 0; i < no; i++) {
      const red = i * (360 / no) * (Math.PI / 180);
      const x = Math.cos(red) * 1.67;
      const y = Math.sin(red) * 1.67;
      const quatX = new CANNON.Quaternion();
      quatX.setFromAxisAngle(new CANNON.Vec3(0, 0, 1), rads);
      connonDial.addShape(box, new CANNON.Vec3(x, y, 0), quatX);
      rads += Math.PI * 0.125;
    }

    connonDial.position.set(0, 2, 0);
    connonDial.type = CANNON.Body.STATIC;
    connonDial.mass = 0;
    connonDial.updateMassProperties();
    connonDial.aabbNeedsUpdate = true;

    const axis = new CANNON.Vec3(1, 0, 0);
    const angle = Math.PI / 2;
    connonDial.quaternion.setFromAxisAngle(axis, angle);
    connonWorld.world.add(connonDial);
    addVisual(scene, connonDial, 'base');
  };
  const setConnonDialPosition = () => {
    object3DDialGlass = object3DDial.getObjectByName('Cylinder026');

    const rot = new THREE.Quaternion();
    object3DDialGlass.getWorldQuaternion(rot);
    const pos = new THREE.Vector3();
    object3DDialGlass.getWorldPosition(pos);

    connonDial.quaternion.copy(rot);
    connonDial.position.copy(pos);

    resetDimondPosition();
  };

  const resetDimondPosition = () => {
    mCannonDimonds.forEach((element, i) => {
      const dimond1 = object3DDial.getObjectByName(STR_DIMOND[i]);
      const rot = new THREE.Quaternion();
      dimond1.getWorldQuaternion(rot);
      const pos = new THREE.Vector3();
      dimond1.getWorldPosition(pos);
      mCannonDimonds[i].quaternion.copy(rot);
      mCannonDimonds[i].position.copy(pos);
      object3DDial.getObjectByName(STR_DIMOND[i]).visible = false;
      object3DDial.getObjectByName(STR_DIMOND_COVER[i]).visible = false;
    });
  };

  const focusCall = (item) => {
    selectedItem = item;
    focusedModel = itemsArray[item];
    focus(
      focusedModel,
      { x: 0, y: 0, z: 22 },
      { x: 0, y: 0, z: 0 },
      { x: 0.5, y: 0.5, z: 0.5 }
    );
  };
  const unFoucosCall = () => null;
  const focus = (model, pos, rot, scale) => {
    const tweenTime = 1000;
    isTweening = true;
    const obj = model;
    new TWEEN.Tween(obj.rotation)
      .to(
        {
          x: Math.PI * rot.x,
          y: Math.PI * rot.y,
          z: Math.PI * rot.z
        },
        tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();

    new TWEEN.Tween(obj.scale)
      .to(
        {
          x: scale.x,
          y: scale.y,
          z: scale.z
        },
        tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();

    new TWEEN.Tween(obj.position)
      .to({
        x: pos.x,
        y: pos.y,
        z: pos.z
      })
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        isTweening = false;
        updateObjectControlForModel(obj);
        setConnonDialPosition();
      })
      .start();
  };

  const createRayCasterCatcher = (rayCasterScale) =>
    new THREE.Mesh(
      new THREE.BoxBufferGeometry(
        rayCasterScale.x,
        rayCasterScale.y,
        rayCasterScale.z
      ),
      new THREE.MeshPhongMaterial({
        color: 0xff0000,
        transparent: true,
        opacity: 0.5
      })
    );

  function loadObject(modelUrl, id, itemGroup) {
    const gltfLoader = new GLTFLoader(loadingManager);
    const ItemId = id;
    const group = itemGroup;
    gltfLoader.load(modelUrl, (gltf) => {
      const root = gltf.scene;
      object3DDial = root;
      itemsChildForCatchingClick[ItemId] = createRayCasterCatcher({
        x: 5,
        y: 8,
        z: 5
      });
      itemsChildForCatchingClick[ItemId].visible = false;
      itemsChildForCatchingClick[ItemId].position.set(0, 1.2, 1);
      group.name = id;
      group.add(root);
      group.add(itemsChildForCatchingClick[ItemId]);
      scene.add(group);
      group.scale.set(0.2, 0.2, 0.2);

      glassCylender = new THREE.Mesh(
        new THREE.CylinderGeometry(2, 2, 0.1, 8),
        new THREE.MeshLambertMaterial({
          color: 0xff0000,
          transparent: true,
          opacity: 1.6
        })
      );
      root.add(glassCylender);
      glassCylender.visible = false;
      const glass = object3DDial.getObjectByName('Cylinder026');
      glassCylender.position.copy(glass.position);
      glassCylender.position.z -= 0.1;
      glassCylender.rotation.set(Math.PI * 0.5, 0, 0);
      const dimond = object3DDial.getObjectByName(STR_DIMOND[0]);
      const dimondLayer = object3DDial.getObjectByName(STR_DIMOND_COVER[0]);
      for (let i = 0; i < NO_OF_DIMOND; i++) {
        object3dDimonds.push(new THREE.Group());
        const clonedimond = dimond.clone();
        const clonedimondLayer = dimondLayer.clone();
        clonedimond.position.set(0, 0, 0);
        clonedimondLayer.position.set(0, 0, 0);
        clonedimond.scale.set(0.5, 0.5, 0.5);
        clonedimondLayer.scale.set(0.5, 0.5, 0.5);
        object3dDimonds[i].add(clonedimond);
        object3dDimonds[i].add(clonedimondLayer);
        scene.add(object3dDimonds[i]);
      }
    });
  }

  function loadStarp(modelUrl, group) {
    const gltfLoader = new GLTFLoader(loadingManager);
    const ItemGroup = group;
    gltfLoader.load(modelUrl, (gltf) => {
      ItemGroup.add(gltf.scene);
    });
  }

  const canvasRef = React.useRef();
  const loadThreeJsModulesAsync = async () => {
    GLTFLoader = (await import('three/examples/jsm/loaders/GLTFLoader'))
      .GLTFLoader;
    RGBELoader = (await import('three/examples/jsm/loaders/RGBELoader'))
      .RGBELoader;
    TWEEN = (await import('three/examples/jsm/libs/tween.module.min.js')).TWEEN;
    ObjectControls = (await import('../common/ObjectControls')).ObjectControls;
  };

  const updateObjectControlForModel = (model) => {
    if (
      model === undefined ||
      camera === undefined ||
      renderer === undefined ||
      viewOnly
    )
      return;
    if (!controls) {
      controls = new ObjectControls(camera, renderer.domElement, model);
    } else {
      controls.setObjectToMove(model);
    }
    controls.setDistance(2, 400);
    controls.setZoomSpeed(0.5);
    controls.enableVerticalRotation();
    controls.enableHorizontalRotation();
    controls.disableZoom();
    controls.setRotationSpeed(0.1);
  };
  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  };
  const eventDown = (e) => {
    touchEvent(e, touchType.touchDown, 0);
  };
  const eventMove = (e) => {
    touchEvent(e, touchType.touchMove, 0);
  };
  const eventUp = (e) => {
    touchEvent(e, touchType.touchUp, 0);
  };
  const addEventListeners = () => {
    document.addEventListener('mousedown', eventDown);
    document.addEventListener('touchstart', eventDown);

    document.addEventListener('mousemove', eventMove);
    document.addEventListener('touchmove', eventMove);

    document.addEventListener('mouseup', eventUp);
    document.addEventListener('touchend', eventUp);

    // document.addEventListener('wheel', onwheelEvent);
    window.addEventListener('resize', onWindowResize, false);
  };

  const detectFirstTouchedObject = (e, type, sys, objects) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches) {
      if (e.touches.length > 0) {
        mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
      }
    } else {
      const elem = renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(objects);
    if (intersects.length) {
      return intersects[0];
    }
  };

  function touchEvent(e, type, sys) {
    if (isTweening || !itemsArray[0] || loadingCount === 0) return;

    if (touchType.touchDown === type) {
      isDown = true;
    }
    if (touchType.touchUp === type) {
      isDown = false;
    }
    if (focusedModel && isDown == true) {
      updateRemoteObjectControlForModel();
    }

    if (type === touchType.touchUp && !focusedModel) {
      const clickedModel = detectFirstTouchedObject(
        e,
        type,
        sys,
        itemsChildForCatchingClick
      );
      if (clickedModel && clickedModel.object) {
        for (let i = 0; i < itemsChildForCatchingClick.length; i++) {
          if (itemsChildForCatchingClick[i] === clickedModel.object) {
            focusCall(i);
            updateRemoteState({ flag: REMOTE_ACTION.FOCUS, focusItem: i });
          }
        }
      }
    }
  }

  function onwheelEvent(event) {
    if (isTweening || !focusedModel) return;
    let scl = focusedModel.scale.x;
    if (event.deltaY > 0) {
      if (scl < maxScale) scl += incScale;
    } else {
      if (scl > minScale) scl -= incScale;
    }
    focusedModel.scale.set(scl, scl, scl);
  }
  const checkIsInDial = (object1, object2) => {
    object1.geometry.computeBoundingBox(); //not needed if its already calculated
    object2.geometry.computeBoundingBox();
    object1.updateMatrixWorld();
    object2.updateMatrixWorld();

    const box1 = object1.geometry.boundingBox.clone();
    box1.applyMatrix4(object1.matrixWorld);
    //
    const box2 = object2.geometry.boundingBox.clone();
    box2.applyMatrix4(object2.matrixWorld);
    //
    return box1.intersectsBox(box2);
  };
  const animate = () => {
    if (renderer === undefined || scene === undefined) {
      return;
    }

    if (object3DDialGlass) {
      const rot = new THREE.Quaternion();
      object3DDialGlass.getWorldQuaternion(rot);
      const pos = new THREE.Vector3();
      object3DDialGlass.getWorldPosition(pos);
      connonDial.quaternion.copy(rot);
      connonDial.position.copy(pos);

      if (lastGlassPos.x.toFixed(1) !== pos.x.toFixed(1)) {
        resetDimondPosition();
      }

      lastGlassPos.x = pos.x;
      lastGlassPos.y = pos.y;
      lastGlassPos.z = pos.z;

      mCannonDimonds.forEach((element, i) => {
        if (!checkIsInDial(glassCylender, element.threemesh.children[0])) {
          resetDimondPosition();
        }
        object3dDimonds[i].position.copy(element.position);
        object3dDimonds[i].quaternion.copy(rot);
      });
    }
    if (artisanRemoteState) remoteObjectUpdate();
    connonWorld.world.step(0.017);
    updateBodies(connonWorld.world);
    TWEEN.update();
    renderer.render(scene, camera);
    requestAnimationFrame(animate);
  };

  const remoteObjectUpdate = () => {
    if (loadingCount > 0) {
      loadingCount++;
    }
    if (
      artisanRemoteState &&
      artisanRemoteState?.flag &&
      itemsArray[0] &&
      loadingCount > 10
    ) {
      if (artisanRemoteState.flag == REMOTE_ACTION.FOCUS) {
        focusCall(artisanRemoteState.focusItem);
      }
      if (artisanRemoteState.flag == REMOTE_ACTION.UN_FOCUS) {
        unFoucosCall();
      }
      if (
        artisanRemoteState.flag == REMOTE_ACTION.MOUSE_CONTROL &&
        !isTweening
      ) {
        if (
          focusedModel?.ItemId != selectedItem &&
          artisanRemoteState?.focusItem >= 0 &&
          artisanRemoteState?.focusItem < itemsArray.length
        ) {
          focusedModel = itemsArray[artisanRemoteState.focusItem];
          selectedItem = artisanRemoteState.focusItem;
        }
        if (focusedModel) {
          focusedModel.position.set(
            artisanRemoteState.position[0],
            artisanRemoteState.position[1],
            artisanRemoteState.position[2]
          );
          focusedModel.scale.set(
            artisanRemoteState.scale[0],
            artisanRemoteState.scale[1],
            artisanRemoteState.scale[2]
          );
          focusedModel.rotation.set(
            artisanRemoteState.rotation[0],
            artisanRemoteState.rotation[1],
            artisanRemoteState.rotation[2]
          );
        }
      }
      artisanRemoteState.flag = undefined;
      artisanRemoteState = undefined;
    }
  };

  const loadEnvironment = () => {
    const textureLoader = new THREE.TextureLoader(loadingManager);
    const texture = textureLoader.load(mainAtrium);
    texture.encoding = THREE.sRGBEncoding;
    const mashAtrium = new THREE.Mesh(
      new THREE.PlaneGeometry(125, 93),
      new THREE.MeshBasicMaterial({ map: texture })
    );
    mashAtrium.position.set(0.4, 9, -50);
    mashAtrium.position.set(0, 0.51, -50);
    scene.add(mashAtrium);

    const loader = new RGBELoader();
    loader.setDataType(THREE.UnsignedByteType);
    const modelBackgroundPath = interiorHDR;
    modelBackgroundPath &&
      loader.load(
        modelBackgroundPath,
        (texture) => {
          if (renderer && scene) {
            let pmremGenerator = new THREE.PMREMGenerator(renderer);
            pmremGenerator.compileEquirectangularShader();
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;
            pmremGenerator = undefined;
            scene.environment = envMap;
          }
        },
        undefined,
        undefined
      );
  };

  const cleanMaterial = (material) => {
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }
    material.dispose();
    material = undefined;
  };
  const cleanUp = () => {
    itemsArray.length = 0;
    itemsChildForCatchingClick.length = 0;
    if (scene !== undefined) {
      scene.traverse((object) => {
        if (!object['isMesh']) return;
        object['geometry'].dispose();
        if (object['material'].isMaterial) {
          cleanMaterial(object['material']);
        } else {
          // an array of materials
          for (const material of object['material']) cleanMaterial(material);
        }
        object['geometry'] = undefined;
        object = undefined;
      });
      scene.children.forEach((model) => {
        scene.remove(model);
      });
    }

    if (renderer !== undefined) {
      renderer.dispose();
      renderer && renderer.renderLists.dispose();
    }
    if (controls) {
      controls.disableVerticalRotation();
      controls.disableHorizontalRotation();
      controls.disableZoom();
    }
    document.removeEventListener('touchstart', eventDown);
    document.removeEventListener('touchmove', eventMove);
    document.removeEventListener('touchend', eventUp);
    document.removeEventListener('mousedown', eventDown);
    document.removeEventListener('mousemove', eventMove);
    document.removeEventListener('mouseup', eventUp);
    document.removeEventListener('wheel', onwheelEvent);
    window.removeEventListener('resize', onWindowResize);
    controls = undefined;
    scene = undefined;
    renderer = undefined;
    camera = undefined;
  };
  React.useEffect(() => {
    if (remoteState?.flag) {
      artisanRemoteState = remoteState;
    }
  }, [remoteState]);

  React.useEffect(() => {
    const canvas = document.querySelector('#c') as HTMLCanvasElement;
    camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      200
    );
    renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true
    });

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    renderer.outputEncoding = THREE.sRGBEncoding;

    camera.position.set(0, 0, 30);

    const color = 0xeeeeee;
    const ambientLight = new THREE.AmbientLight(color, 0.21);
    ambientLight.name = 'ambient_light';
    const sixtyDegreeLight = new THREE.DirectionalLight(color, 0.21);
    sixtyDegreeLight.position.set(1.5, 1.2, -0.5);
    sixtyDegreeLight.name = 'main_light';
    scene.add(sixtyDegreeLight);

    loadThreeJsModulesAsync().then(() => {
      loadEnvironment();
      itemsArray[0] = new THREE.Group();
      loadObject(dialModel, 0, itemsArray[0]);
      loadStarp(mainStrap, itemsArray[0]);
      if (!viewOnly) {
        addEventListeners();
      }
      animate();
    });

    return () => {
      // everything here will be called on unmount
      // clean up
      cleanUp();
    };
  }, []);

  return (
    <div>
      <canvas id="c" ref={canvasRef}></canvas>
      <style jsx>
        {`
          #c {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
          :global(.in-meeting.MDLandscape) #c {
            left: ${MDLandscapeNormalSpec.contentArea.left / 2}px;
          }
          :global(.in-meeting.SMLandscape) #c {
            left: ${SMLandscapeNormalSpec.contentArea.left / 2}px;
          }
          #loading {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 5;
            background: rgba(0, 0, 0, 0.75);
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 2em;
          }
        `}
      </style>
    </div>
  );
};

export default Artisan;
