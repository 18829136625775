import React from 'react';
import { BsSearch } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import * as THREE from 'three';
import {
  IChopard3DAnimationState,
  REMOTE_ACTION
} from '../../../interfaces/chopard';
import { actionShowChopardProductDetails } from '../../../redux/customActions/chopard';
import {
  dial,
  interiorHDR,
  leatherStrap,
  artisansBackground,
  mainStrapGLB,
  stainlessStrap
} from './assets';

let atriumRemoteState = undefined;

const Atrium = ({
  remoteState,
  updateRemoteState,
  onReady,
  viewOnly,
  detailsOpened
}: {
  remoteState?: IChopard3DAnimationState;
  updateRemoteState: (state: IChopard3DAnimationState) => void;
  onReady: () => void;
  viewOnly?: boolean;
  detailsOpened?:boolean;
}) => {
  let camera, GLTFLoader, RGBELoader, TWEEN, ObjectControls, renderer;
  let scene = new THREE.Scene();
  let controls = undefined;
  let isTweening = false;
  let mashBlack, mashMat;
  let focusedModel = undefined;
  let isExplore = false;
  let buttonDiv;
  let selectedItem = 0;
  let isDown = false;
  let loadingCount = 0;
  const mouse = new THREE.Vector2();
  const dounMouse = new THREE.Vector2();
  const raycaster = new THREE.Raycaster();
  const maxScale = 0.7;
  const minScale = 0.25;
  const incScale = 0.02;
  const itemsArray = Array(3);
  const itemsChildForCatchingClick = Array(3);

  const dispatch = useDispatch();
  const eventExplore = () => {
    explore();
    updateRemoteState({
      flag: REMOTE_ACTION.EXPLORE
    });
  };
  const hideExploreButton = () => {
    if (buttonDiv) {
      buttonDiv.style.display = 'none';
    }
  };
  const showExploreButton = () => {
    if (buttonDiv) {
      buttonDiv.style.display = 'block';
    }
  };
  const explore = () => {
    hideExploreButton();
    for (let i = 0; i < itemsArray.length; i++) {
      itemsArray[i].visible = true;
      if (i != 1) itemsArray[i].position.z = 20;
    }
    isExplore = true;
  };
  const updateRemoteObjectControlForModel = () => {
    if (!focusedModel) return;
    updateRemoteState({
      flag: REMOTE_ACTION.MOUSE_CONTROL,
      focusItem: selectedItem,
      position: [
        focusedModel.position.x,
        focusedModel.position.y,
        focusedModel.position.z
      ],
      scale: [focusedModel.scale.x, focusedModel.scale.y, focusedModel.scale.z],
      rotation: [
        focusedModel.rotation.x,
        focusedModel.rotation.y,
        focusedModel.rotation.z
      ]
    });
  };

  const callback = () => {
    onReady();
    loadingCount = 1;
    for (let i = 0; i < itemsArray.length; i++) {
      if (itemsArray[i]) {
        itemsChildForCatchingClick[i] = createRayCasterCatcher({
          x: 4,
          y: 7,
          z: 1
        });
        itemsChildForCatchingClick[i].visible = false;
        itemsChildForCatchingClick[i].position.set(0, 1.2, 1);
        itemsArray[i].add(itemsChildForCatchingClick[i]);
        scene.add(itemsArray[i]);
        itemsArray[i].position.set(-3.5 + i * 3.5, 0, 0);
        itemsArray[i].visible = i === 1;
      }
    }
  };
  const loadingManager = new THREE.LoadingManager(() => {
    callback();
  });
  const touchType = {
    touchDown: 'touchDown',
    touchMove: 'touchMove',
    touchUp: 'touchUp'
  };
  const strapType = {
    DIAL: 'DIAL',
    LEATHERSTRAP: 'LEATHERSTRAP',
    MAINSTRAP: 'MAINSTRAP',
    STAINLESSSTRAP: 'STAINLESSSTRAP'
  };
  const canvasRef = React.useRef();
  const loadThreeJsModulesAsync = async () => {
    GLTFLoader = (await import('three/examples/jsm/loaders/GLTFLoader'))
      .GLTFLoader;
    RGBELoader = (await import('three/examples/jsm/loaders/RGBELoader'))
      .RGBELoader;
    TWEEN = (await import('three/examples/jsm/libs/tween.module.min.js')).TWEEN;
    ObjectControls = (await import('../common/ObjectControls')).ObjectControls;
  };

  const focus = (model, pos, rot, scale) => {
    const tweenTime = 1000;
    isTweening = true;
    const obj = model;
    mashBlack.visible = true;
    new TWEEN.Tween(obj.rotation)
      .to(
        {
          x: rot.x,
          y: rot.y,
          z: rot.z
        },
        tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();

    new TWEEN.Tween(obj.scale)
      .to(
        {
          x: scale.x,
          y: scale.y,
          z: scale.z
        },
        tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();

    new TWEEN.Tween(obj.position)
      .to({
        x: pos.x,
        y: pos.y,
        z: pos.z
      })
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        isTweening = false;
        updateObjectControlForModel(obj);
      })
      .start();

    new TWEEN.Tween(mashMat)
      .to({ opacity: 0.5 }, tweenTime)
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
  };

  const item_ChangeStrap = (model, group, pos, rot, scale) => {
    const tweenTime = 1000;
    isTweening = true;
    const obj = model;
    const gobj = group;
    new TWEEN.Tween(obj.rotation)
      .to(
        {
          x: rot.x,
          y: rot.y,
          z: rot.z
        },
        tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();

    new TWEEN.Tween(obj.scale)
      .to(
        {
          x: scale.x,
          y: scale.y,
          z: scale.z
        },
        tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();

    new TWEEN.Tween(obj.position)
      .to({
        x: pos.x,
        y: pos.y,
        z: pos.z
      })
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        isTweening = false;
        obj.position.set(0, 0, 0);
        obj.rotation.set(0, 0, 0);
        obj.scale.set(1, 1, 1);
        gobj.add(obj);
      })
      .start();
  };
  const focusCall = (item) => {
    selectedItem = item;
    focusedModel = itemsArray[item];
    focus(
      focusedModel,
      { x: 0, y: 0, z: 20 },
      { x: 0, y: 0, z: 0 },
      { x: 0.5, y: 0.5, z: 0.5 }
    );
  };
  const unFoucosCall = () => null;

  const checkChangeStrap = (items) => {
    const obj = [];
    itemsArray.forEach((element, i) => {
      obj.push(element.getObjectByName(element.objName));
      element.remove(obj[i]);
    });

    for (let i = 0; i < items.length; i++) {
      for (let j = 0; j < items.length; j++) {
        if (items[i] === obj[j].name) {
          itemsArray[i].add(obj[j]);
          itemsArray[i].objName = obj[j].name;
        }
      }
    }
  };

  const focusChangeStrap = (item) => {
    const obj = itemsArray[item].getObjectByName(itemsArray[item].objName);
    const fObj = focusedModel.getObjectByName(focusedModel.objName);

    const pos = new THREE.Vector3();
    const rot = new THREE.Quaternion();
    const scl = new THREE.Vector3();

    const fpos = new THREE.Vector3();
    const frot = new THREE.Quaternion();
    const fscl = new THREE.Vector3();

    obj.getWorldPosition(pos);
    obj.getWorldQuaternion(rot);
    obj.getWorldScale(scl);

    itemsArray[0].remove(obj);

    obj.position.set(pos.x, pos.y, pos.z);
    obj.scale.set(scl.x, scl.y, scl.z);
    obj.rotation.set(rot.x, rot.y, rot.z);

    fObj.getWorldPosition(fpos);

    fObj.getWorldQuaternion(frot);

    fObj.getWorldScale(fscl);

    focusedModel.remove(fObj);
    fObj.position.set(fpos.x, fpos.y, fpos.z);
    fObj.scale.set(fscl.x, fscl.y, fscl.z);
    // fObj.rotation.set(frot.x, frot.y, frot.z);
    fObj.applyQuaternion(frot);
    scene.add(obj);
    scene.add(fObj);

    const temp = focusedModel.objName;
    focusedModel.objName = itemsArray[item].objName;
    itemsArray[item].objName = temp;

    item_ChangeStrap(
      fObj,
      itemsArray[item],
      { x: -3.5 + item * 3.5, y: 0, z: 20 },
      { x: 0, y: 0, z: 0 },
      { x: 0.25, y: 0.25, z: 0.25 }
    );

    item_ChangeStrap(
      obj,
      focusedModel,
      focusedModel.position,
      focusedModel.rotation,
      focusedModel.scale
    );
  };
  const createRayCasterCatcher = (rayCasterScale) =>
    new THREE.Mesh(
      new THREE.BoxBufferGeometry(
        rayCasterScale.x,
        rayCasterScale.y,
        rayCasterScale.z
      ),
      new THREE.MeshPhongMaterial({
        color: 0xff0000,
        transparent: true,
        opacity: 0.5
      })
    );

  function loadObject(modelUrl, group, name) {
    const gltfLoader = new GLTFLoader(loadingManager);
    const obj = group;
    const Itemname = name;
    group.objName = name;
    gltfLoader.load(modelUrl, (gltf) => {
      const root = gltf.scene;
      obj.add(root);
      root.name = Itemname;
      obj.scale.set(0.25, 0.25, 0.25);
    });
  }

  const updateObjectControlForModel = (model) => {
    if (
      model === undefined ||
      camera === undefined ||
      renderer === undefined ||
      viewOnly
    )
      return;
    if (!controls) {
      controls = new ObjectControls(camera, renderer.domElement, model);
    } else {
      controls.setObjectToMove(model);
    }
    controls.setDistance(2, 400);
    controls.setZoomSpeed(0.5);
    controls.enableVerticalRotation();
    controls.enableHorizontalRotation();
    controls.disableZoom();
    controls.setRotationSpeed(0.1);
  };
  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  };
  const eventDown = (e) => {
    touchEvent(e, touchType.touchDown);
  };
  const eventMove = (e) => {
    touchEvent(e, touchType.touchMove);
  };
  const eventUp = (e) => {
    touchEvent(e, touchType.touchUp);
  };
  const addEventListeners = () => {
    document.addEventListener('mousedown', eventDown);
    document.addEventListener('touchstart', eventDown);

    document.addEventListener('mousemove', eventMove);
    document.addEventListener('touchmove', eventMove);

    document.addEventListener('mouseup', eventUp);
    document.addEventListener('touchend', eventUp);

    document.addEventListener('wheel', onwheelEvent);
    window.addEventListener('resize', onWindowResize, false);
  };

  const setTouchValues = (e, type) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches != null) {
      if (e.touches.length > 0) {
        mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
      }
    } else {
      const elem = renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    if (type === touchType.touchDown) {
      dounMouse.x = mouse.x;
      dounMouse.y = mouse.y;
    }
  };

  const detectFirstTouchedObject = (objects) => {
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(objects);
    if (intersects.length) {
      return intersects[0];
    }
  };

  function touchEvent(e, type) {
    if (!itemsArray[0] || loadingCount === 0) return;

    if (touchType.touchDown === type) {
      isDown = true;
    }
    if (touchType.touchUp === type) {
      isDown = false;
    }
    if (focusedModel && isDown == true) {
      updateRemoteObjectControlForModel();
    }

    setTouchValues(e, type);
    if (isTweening) return;
    if (type === touchType.touchUp && !focusedModel) {
      const clickedModel = detectFirstTouchedObject(itemsChildForCatchingClick);
      if (clickedModel && clickedModel.object) {
        for (let i = 0; i < itemsChildForCatchingClick.length; i++) {
          if (itemsChildForCatchingClick[i] == clickedModel.object) {
            focusCall(i);
            updateRemoteState({ flag: REMOTE_ACTION.FOCUS, focusItem: i });
            showExploreButton();
          }
        }
      }
    }
    if (
      type === touchType.touchUp &&
      focusedModel &&
      isExplore &&
      dounMouse.x === mouse.x &&
      dounMouse.y === mouse.y
    ) {
      const clickedModel = detectFirstTouchedObject(itemsChildForCatchingClick);
      if (clickedModel && clickedModel.object) {
        for (let i = 0; i < itemsChildForCatchingClick.length; i++) {
          if (
            itemsChildForCatchingClick[i] == clickedModel.object &&
            focusedModel != itemsArray[i]
          ) {
            const stripsName = [];
            itemsArray.forEach((element) => {
              stripsName.push(element.objName);
            });
            updateRemoteState({
              flag: REMOTE_ACTION.CHANGE_STRAP,
              focusItem: i,
              strArray: stripsName
            });
            focusChangeStrap(i);
          }
        }
      }
    }
  }

  function onwheelEvent(event) {
    if (isTweening || !focusedModel || window['detailsOpened']) return;
    let scl = focusedModel.scale.x;
    if (event.deltaY > 0) {
      if (scl < maxScale) scl += incScale;
    } else {
      if (scl > minScale) scl -= incScale;
    }
    focusedModel.scale.set(scl, scl, scl);
    updateRemoteObjectControlForModel();
  }
  const remoteObjectUpdate = () => {
    if (loadingCount > 0) {
      loadingCount++;
    }
    if (
      atriumRemoteState &&
      atriumRemoteState?.flag &&
      itemsArray[0] &&
      loadingCount > 10
    ) {
      if (atriumRemoteState.flag == REMOTE_ACTION.FOCUS) {
        focusCall(atriumRemoteState.focusItem);
      }
      if (atriumRemoteState.flag == REMOTE_ACTION.CHANGE_STRAP) {
        checkChangeStrap(atriumRemoteState.strArray);
        focusChangeStrap(atriumRemoteState.focusItem);
      }
      if (atriumRemoteState.flag == REMOTE_ACTION.UN_FOCUS) {
        unFoucosCall();
      }
      if (atriumRemoteState.flag == REMOTE_ACTION.EXPLORE) {
        explore();
      }
      if (
        atriumRemoteState.flag == REMOTE_ACTION.MOUSE_CONTROL &&
        !isTweening
      ) {
        if (
          focusedModel?.ItemId != selectedItem &&
          atriumRemoteState?.focusItem >= 0 &&
          atriumRemoteState?.focusItem < itemsArray.length
        ) {
          focusedModel = itemsArray[atriumRemoteState.focusItem];
          selectedItem = atriumRemoteState.focusItem;
        }
        if (focusedModel) {
          focusedModel.position.set(
            atriumRemoteState.position[0],
            atriumRemoteState.position[1],
            atriumRemoteState.position[2]
          );
          focusedModel.scale.set(
            atriumRemoteState.scale[0],
            atriumRemoteState.scale[1],
            atriumRemoteState.scale[2]
          );
          focusedModel.rotation.set(
            atriumRemoteState.rotation[0],
            atriumRemoteState.rotation[1],
            atriumRemoteState.rotation[2]
          );
        }
      }
      atriumRemoteState.flag = undefined;
      atriumRemoteState = undefined;
    }
  };
  const animate = () => {
    if (renderer == undefined || scene == undefined) {
      return;
    }
    if(focusedModel && controls){
      if(window['detailsOpened']){
        controls.disableVerticalRotation();
        controls.disableHorizontalRotation();
      }else{
        controls.enableVerticalRotation();
        controls.enableHorizontalRotation();
      }
    }
    if (atriumRemoteState) remoteObjectUpdate();
    TWEEN.update();
    renderer.render(scene, camera);
    requestAnimationFrame(animate);
  };

  const loadEnvironment = () => {
    const textureLoader = new THREE.TextureLoader(loadingManager);
    const texture = textureLoader.load(artisansBackground);
    texture.encoding = THREE.sRGBEncoding;
    const mashAtrium = new THREE.Mesh(
      new THREE.PlaneGeometry(136, 93),
      new THREE.MeshBasicMaterial({ map: texture })
    );

    mashAtrium.position.set(0, 0, -50);
    mashAtrium.position.set(0.4, 9, -50);
    scene.add(mashAtrium);

    mashMat = new THREE.MeshBasicMaterial({
      color: 0x000000,
      transparent: true,
      opacity: 0.01
    });

    mashBlack = new THREE.Mesh(new THREE.PlaneGeometry(136, 93), mashMat);
    mashBlack.position.set(0, 0, -48);
    scene.add(mashBlack);
    mashBlack.visible = false;

    const loader = new RGBELoader();
    loader.setDataType(THREE.UnsignedByteType);
    const modelBackgroundPath = interiorHDR;
    modelBackgroundPath &&
      loader.load(
        modelBackgroundPath,
        (texture) => {
          if (renderer && scene) {
            let pmremGenerator = new THREE.PMREMGenerator(renderer);
            pmremGenerator.compileEquirectangularShader();
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;
            pmremGenerator = undefined;
            scene.environment = envMap;
          }
        },
        undefined,
        undefined
      );
  };

  const cleanMaterial = (material) => {
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }
    material.dispose();
    material = undefined;
  };
  const cleanUp = () => {
    itemsArray.length = 0;
    itemsChildForCatchingClick.length = 0;
    if (scene != undefined) {
      scene.traverse((object) => {
        if (!object['isMesh']) return;
        object['geometry'].dispose();
        if (object['material'].isMaterial) {
          cleanMaterial(object['material']);
        } else {
          // an array of materials
          for (const material of object['material']) cleanMaterial(material);
        }
        object['geometry'] = undefined;
        object = undefined;
      });
      scene.children.forEach((model) => {
        scene.remove(model);
      });
    }

    if (renderer != undefined) {
      renderer.dispose();
      renderer && renderer.renderLists.dispose();
    }
    if (controls) {
      controls.disableVerticalRotation();
      controls.disableHorizontalRotation();
      controls.disableZoom();
    }
    document.removeEventListener('touchstart', eventDown);
    document.removeEventListener('touchmove', eventMove);
    document.removeEventListener('touchend', eventUp);
    document.removeEventListener('mousedown', eventDown);
    document.removeEventListener('mousemove', eventMove);
    document.removeEventListener('mouseup', eventUp);
    document.removeEventListener('wheel', onwheelEvent);
    window.removeEventListener('resize', onWindowResize);
    controls = undefined;
    scene = undefined;
    renderer = undefined;
    camera = undefined;
  };
  React.useEffect(() => {
    if (remoteState?.flag) {
      atriumRemoteState = remoteState;
    }
  }, [remoteState]);
  React.useEffect(() => {
    const canvas = document.querySelector('#c') as HTMLCanvasElement;
    camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      200
    );
    renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true
    });

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    renderer.outputEncoding = THREE.sRGBEncoding;

    camera.position.set(0, 0, 30);

    const color = 0xeeeeee;
    const ambientLight = new THREE.AmbientLight(color, 0.21);
    ambientLight.name = 'ambient_light';
    const sixtyDegreeLight = new THREE.DirectionalLight(color, 0.21);
    sixtyDegreeLight.position.set(1.5, 1.2, -0.5);
    sixtyDegreeLight.name = 'main_light';
    scene.add(sixtyDegreeLight);

    buttonDiv = document.getElementById('centertxt');
    window['showStrapSelector'] = eventExplore;
    hideExploreButton();
    loadThreeJsModulesAsync().then(() => {
      loadEnvironment();
      itemsArray[0] = new THREE.Group();
      itemsArray[1] = new THREE.Group();
      itemsArray[2] = new THREE.Group();
      loadObject(dial, itemsArray[1], strapType.DIAL);
      loadObject(leatherStrap, itemsArray[0], strapType.LEATHERSTRAP);
      loadObject(mainStrapGLB, itemsArray[1], strapType.MAINSTRAP);
      loadObject(stainlessStrap, itemsArray[2], strapType.STAINLESSSTRAP);
      if (!viewOnly) {
        addEventListeners();
      }
      animate();
    });

    return () => {
      // everything here will be called on unmount
      // clean up
      cleanUp();
    };
  }, []);
  React.useEffect(()=>{
    window['detailsOpened'] = detailsOpened;
  },[detailsOpened])

  return (
    <div>
      <canvas id="c" ref={canvasRef}></canvas>
      {!viewOnly && (
        <span id="centertxt">
          <button
            className="btn btn-outline-light btn-lg with-shadow"
            onClick={() => {
              dispatch(actionShowChopardProductDetails(true));
            }}
          >
            <BsSearch color="#fff" />
            DISCOVER MORE
          </button>
        </span>
      )}
      <style jsx>
        {`
          #c {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
          #centertxt {
            position: fixed;
            left: 50%;
            bottom: 100px;
            transform: translateX(-50%);
            margin: 0 auto;
          }
          .btn {
            background: transparent;
            border-radius: 10px;
          }
          .btn:hover {
            color: #fff;
          }
          .btn :global(svg) {
            position: relative;
            top: -1px;
            display: inline-block;
            margin-right: 10px;
          }
        `}
      </style>
    </div>
  );
};

export default Atrium;
