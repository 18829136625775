import * as CANNON from 'cannon';
import * as THREE from 'three';
const DAMPING = 0.01;
export const addBody = (cWorld, shapes, scene, type, pos) => {
  const material = new CANNON.Material();
  const body = new CANNON.Body({ mass: 105, material: material });
  body.addShape(shapes);
  body.position.set(pos.x, pos.y, pos.z);
  const axis = new CANNON.Vec3(1, 0, 0);
  const angle = Math.PI / 2;
  body.quaternion.setFromAxisAngle(axis, angle);
  body.linearDamping = DAMPING;
  cWorld.world.add(body);
  switch (type) {
    case 0:
      addVisual(scene, body, 'sphere');
      break;
    case 1:
      addVisual(scene, body, 'box');
      break;
    case 2:
      addVisual(scene, body, 'cylinder');
      break;
  }

  const material_ground = new CANNON.ContactMaterial(
    cWorld.groundMaterial,
    material,
    {
      friction: 0.0,
      restitution: 0.9
    }
  );
  cWorld.world.addContactMaterial(material_ground);
  return body;
};

export const initPhysics = (scene) => {
  const connonWorld = new CANNON.World();
  connonWorld.broadphase = new CANNON.NaiveBroadphase();
  connonWorld.gravity.set(0, -10, 0);
  const groundShape = new CANNON.Cylinder(4, 4, 0.3, 20);
  const groundMaterial = new CANNON.Material();
  const groundBody = new CANNON.Body({ mass: 0, material: groundMaterial });
  groundBody.quaternion.setFromAxisAngle(
    new CANNON.Vec3(1, 0, 0),
    -Math.PI / 2
  );
  groundBody.addShape(groundShape);
  connonWorld.add(groundBody);
  addVisual(scene, groundBody, 'ground');
  return { world: connonWorld, groundMaterial: groundMaterial };
};
export const addVisual = (scene, body, name) => {
  body.name = name;
  let mesh;
  if (body instanceof CANNON.Body) mesh = shape2Mesh(body);
  if (mesh) {
    body.threemesh = mesh;
    scene.add(mesh);
  }
};

const shape2Mesh = (body) => {
  const obj = new THREE.Object3D();
  const material = new THREE.MeshLambertMaterial({
    color: 0xff00ff,
    transparent: true,
    opacity: 0.6
  });
  const material2 = new THREE.MeshLambertMaterial({
    color: 0xffff00,
    transparent: true,
    opacity: 1.6
  });
  let index = 0;
  body.shapes.forEach(function (shape) {
    let mesh, submesh, ground, cyl;
    let red = 20,
      h = 10;
    switch (shape.type) {
      case CANNON.Shape.types.SPHERE:
        mesh = new THREE.Mesh(
          new THREE.SphereGeometry(shape.radius, 8, 8),
          material2
        );
        mesh.visible = false;
        break;
      case CANNON.Shape.types.PLANE:
        mesh = new THREE.Object3D();
        submesh = new THREE.Object3D();
        ground = new THREE.Mesh(
          new THREE.PlaneGeometry(10, 10, 4, 4),
          material
        );
        ground.scale.set(100, 100, 100);
        submesh.add(ground);
        mesh.add(submesh);
        break;
      case CANNON.Shape.types.BOX:
        mesh = new THREE.Group();
        break;
      case CANNON.Shape.types.CONVEXPOLYHEDRON:
        mesh = new THREE.Group();
        if (body.name === 'ground') {
          red = 4;
          h = 0.3;
        }
        if (body.name === 'base') {
          red = 2;
          h = 0.1;
        }
        if (body.name === 'cylinder') {
          red = 1.5;
          h = 2;
        }

        cyl = new THREE.Mesh(
          new THREE.CylinderGeometry(red, red, h, 8),
          material
        );
        cyl.rotation.set(-Math.PI * 0.5, 0, 0);
        // mesh.add(cyl);
        break;
    }
    const o = body.shapeOffsets[index];
    const q = body.shapeOrientations[index++];
    mesh.position.set(o.x, o.y, o.z);
    mesh.quaternion.set(q.x, q.y, q.z, q.w);
    obj.add(mesh);
  });
  return obj;
};

export const updateBodies = (world) => {
  world.bodies.forEach(function (body) {
    if (body.threemesh !== undefined) {
      body.threemesh.position.copy(body.position);
      body.threemesh.quaternion.copy(body.quaternion);
    }
  });
};
