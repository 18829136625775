import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Atrium from './Atrium';
import { AnimationContext } from './AnimationContext';
import { canInteractWithAnimation } from '../../hooks/meeting';
import { useRemoteChopardAnimationState } from '../../hooks/chopard';
import {
  ChopardAnimation,
  IChopard3DAnimationState
} from '../../../interfaces/chopard';
import { throttle } from 'lodash';
import { DID_UPDATE_ANIMATION_STATE } from '../../../utils/constants';
import { logEvent } from '../../../analytics';
import { actionDidUpdateChopardAnimation } from '../../../redux/customActions/chopard';
import { IMainState } from '../../../interfaces';

const AtriumWrapper = () => {
  const { setLoaded } = React.useContext(AnimationContext);

  const viewOnly = !canInteractWithAnimation();

  const detailsOpened = useSelector((state:IMainState)=> state?.clientState?.chopard?.showProductDetails)

  const remoteAtriumAnimationState = useRemoteChopardAnimationState(
    ChopardAnimation.ATRIUM
  );

  const dispatch = useDispatch();

  const logEventThrottled = throttle(
    (state: IChopard3DAnimationState) => {
      logEvent(DID_UPDATE_ANIMATION_STATE, DID_UPDATE_ANIMATION_STATE, {
        animation: ChopardAnimation.ATRIUM,
        state
      });
    },
    1000,
    { trailing: true }
  );

  const throttledUpdateRemoteState = throttle(
    (state: IChopard3DAnimationState) => {
      dispatch(actionDidUpdateChopardAnimation(ChopardAnimation.ATRIUM, state));
    },
    50
  );

  const updateRemoteState = (state: IChopard3DAnimationState) => {
    throttledUpdateRemoteState(state);
    logEventThrottled(state);
  };

  return (
    <>
      <Atrium
        remoteState={remoteAtriumAnimationState}
        updateRemoteState={updateRemoteState}
        onReady={() => setLoaded(true)}
        viewOnly={viewOnly}
        detailsOpened={detailsOpened}
      />
    </>
  );
};
export default AtriumWrapper;
