import React from 'react';
import * as THREE from 'three';
import {
  ringImg,
  circusImg,
  clown,
  interiorHDR
} from './assets';
import {
  IChopardCircusState,
  REMOTE_ACTION
} from '../../../interfaces/chopard';
import { MDLandscapeNormalSpec } from '../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../Meeting/MeetingLayout/SMLandscapeNormal';
import { MDPortraitNormalSpec } from '../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMPortraitNormalSpec } from '../../Meeting/MeetingLayout/SMPortraitNormal';
let circusRemoteState = undefined;
const Circus = ({
  remoteState,
  updateRemoteState,
  onReady,
  viewOnly
}: {
  remoteState?: IChopardCircusState;
  updateRemoteState: (state: IChopardCircusState) => void;
  onReady: () => void;
  viewOnly?: boolean;
}) => {
  const mouse = new THREE.Vector2();
  const raycaster = new THREE.Raycaster();
  const maxScale = 3;
  const minScale = 0.5;
  const incScale = 0.06;
  const itemsArray = Array(1);
  const itemsChildForCatchingClick = Array(1);
  const canvasRef = React.useRef();
  let camera, GLTFLoader, RGBELoader, TWEEN, ObjectControls, renderer;
  let scene = new THREE.Scene();
  let controls = undefined;
  const hoop = [];
  let counter = 0;
  let textureRing;
  let buttonDiv;
  let focusedModel = undefined;
  let isTweening = false;
  let loadingCount = 0;
  const updateRemoteObjectControlForModel = () => {
    if (!focusedModel) return;
    const hoopsElement = [];

    hoop.forEach((element) => {
      hoopsElement.push([
        element.obj.position.x,
        element.obj.position.y,
        element.obj.position.z
      ]);
    });

    updateRemoteState({
      flag: REMOTE_ACTION.MOUSE_CONTROL,
      focusItem: 0,
      position: [
        focusedModel.position.x,
        focusedModel.position.y,
        focusedModel.position.z
      ],
      scale: [focusedModel.scale.x, focusedModel.scale.y, focusedModel.scale.z],
      rotation: [
        focusedModel.rotation.x,
        focusedModel.rotation.y,
        focusedModel.rotation.z
      ],
      hoopsPosition: hoopsElement
    });
  };

  const callback = () => {
    onReady();
    loadingCount = 1;
  };
  const loadingManager = new THREE.LoadingManager(() => {
    callback();
  });
  const touchType = {
    touchDown: 'touchDown',
    touchMove: 'touchMove',
    touchUp: 'touchUp'
  };
  const screenType = {
    GamePlay: 'GAMEPLAY',
    GameOver: 'GAMEOVER',
    Home: 'HOME'
  };
  let screen = screenType.Home;

  const CircRectsOverlap = (CRX, CRY, CRDX, CRDY, centerX, centerY, radius) => {
    if (
      Math.abs(centerX - CRX) <= CRDX + radius &&
      Math.abs(centerY - CRY) <= CRDY + radius
    )
      return true;
    return false;
  };
  const HoopClass = (obj3D) => {
    return {
      obj: obj3D,
      spd: 0.1
    };
  };
  const playGame = () => {
    buttonDiv.style.display = 'none';
    screen = screenType.GamePlay;
    hoop.forEach((element) => {
      element.obj.position.x = 1000;
    });
    if (focusedModel) {
      focus(
        focusedModel,
        { x: 0, y: 0, z: 0 },
        { x: 0, y: 0, z: 0 },
        { x: 1.3, y: 1.3, z: 1.3 }
      );
    }
    // focusedModel = undefined;
    
  };
  const gameOver = () => {
    focusedModel = itemsArray[0];
    updateObjectControlForModel(focusedModel);
    focus(
      focusedModel,
      { x: 0, y: 0, z: 20 },
      { x: 0, y: 0, z: 0 },
      { x: 1.5, y: 1.5, z: 1.5 }
    );

    const but = document.getElementById('playbut');
    but.innerHTML = 'PLAY AGAIN';
    buttonDiv.style.display = 'block';
    screen = screenType.GameOver;
  };
  const focus = (model, pos, rot, scale) => {
    const tweenTime = 1000;
    isTweening = true;
    const obj = model;
    new TWEEN.Tween(obj.rotation)
      .to(
        {
          x: Math.PI * rot.x,
          y: Math.PI * rot.y,
          z: Math.PI * rot.z
        },
        tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();

    new TWEEN.Tween(obj.scale)
      .to(
        {
          x: scale.x,
          y: scale.y,
          z: scale.z
        },
        tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();

    new TWEEN.Tween(obj.position)
      .to({
        x: pos.x,
        y: pos.y,
        z: pos.z
      })
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        isTweening = false;
        if(screen === screenType.GamePlay){
          if (controls) {
            controls.disableVerticalRotation();
            controls.disableHorizontalRotation();
          }
        }else{
          updateObjectControlForModel(focusedModel);
        }
        
      })
      .start();
  };
  const focusCall = (item) => {
    focusedModel = itemsArray[item];
    focus(
      focusedModel,
      { x: 0, y: 0, z: 20 },
      { x: 0, y: 0, z: 0 },
      { x: 1.5, y: 1.5, z: 1.5 }
    );
  };
  const unFoucosCall = () => null;
  const createRayCasterCatcher = (rayCasterScale) =>
    new THREE.Mesh(
      new THREE.BoxBufferGeometry(
        rayCasterScale.x,
        rayCasterScale.y,
        rayCasterScale.z
      ),
      new THREE.MeshPhongMaterial({
        color: 0xff0000,
        transparent: true,
        opacity: 0.5
      })
    );

  const loadObject = (modelUrl, id) => {
    const gltfLoader = new GLTFLoader(loadingManager);
    const ItemId = id;
    gltfLoader.load(modelUrl, (gltf) => {
      const group = new THREE.Group();
      const root = gltf.scene;
      root.scale.set(60, 60, 60);
      root.position.set(0, 0, -1.65);
      root.rotation.set(Math.PI * 0.5, -Math.PI * 0.5, 0);
      itemsChildForCatchingClick[ItemId] = createRayCasterCatcher({
        x: 1.7,
        y: 2.2,
        z: 0.25
      });
      itemsChildForCatchingClick[ItemId].visible = false;
      itemsChildForCatchingClick[ItemId].position.set(0, 0, 0);
      group.name = id;
      group.add(root);
      group.add(itemsChildForCatchingClick[ItemId]);
      group['vy'] = 0;
      itemsArray[ItemId] = group;
      scene.add(group);
    });
  };

  const loadHoop = (geometry, texture) => {
    const group = new THREE.Group();
    const material = new THREE.MeshBasicMaterial({ map: texture });
    const torus = new THREE.Mesh(geometry, material);
    group.add(torus);
    scene.add(group);
    const hoopObj = HoopClass(group);
    hoop.push(hoopObj);
    hoop[hoop.length - 1].obj.position.set(1000, -0.81, 0);
  };

  const loadThreeJsModulesAsync = async () => {
    GLTFLoader = (await import('three/examples/jsm/loaders/GLTFLoader'))
      .GLTFLoader;
    RGBELoader = (await import('three/examples/jsm/loaders/RGBELoader'))
      .RGBELoader;
    TWEEN = (await import('three/examples/jsm/libs/tween.module.min.js')).TWEEN;
    ObjectControls = (await import('../common/ObjectControls')).ObjectControls;
  };
  const updateObjectControlForModel = (model) => {
    if (
      model === undefined ||
      camera === undefined ||
      renderer === undefined ||
      viewOnly
    )
      return;
    if (!controls) {
      controls = new ObjectControls(camera, renderer.domElement, model);
    } else {
      controls.setObjectToMove(model);
    }
    controls.setDistance(2, 400);
    controls.setZoomSpeed(0.5);
    controls.enableVerticalRotation();
    controls.enableHorizontalRotation();
    controls.disableZoom();
    controls.setRotationSpeed(0.1);
  };
  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  };
  const eventDown = (e) => {
    touchEvent(e, touchType.touchDown, 0);
  };
  const eventMove = (e) => {
    touchEvent(e, touchType.touchMove, 0);
  };
  const eventUp = (e) => {
    touchEvent(e, touchType.touchUp, 0);
  };
  const addEventListeners = () => {
    document.addEventListener('mousedown', eventDown);
    document.addEventListener('touchstart', eventDown);

    document.addEventListener('mousemove', eventMove);
    document.addEventListener('touchmove', eventMove);

    document.addEventListener('mouseup', eventUp);
    document.addEventListener('touchend', eventUp);

    document.addEventListener('wheel', onwheelEvent);
    window.addEventListener('resize', onWindowResize, false);
  };
  const detectFirstTouchedObject = (e, type, sys, objects) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches != null) {
      if (e.touches.length > 0) {
        mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
      }
    } else {
      const elem = renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(objects);
    if (intersects.length) {
      return intersects[0];
    }
  };
  const touchEvent = (e, type, sys) => {
    if (isTweening) return;
    if (type === touchType.touchUp && !focusedModel) {
      const clickedModel = detectFirstTouchedObject(
        e,
        type,
        sys,
        itemsChildForCatchingClick
      );
      if (clickedModel && clickedModel.object) {
        for (let i = 0; i < itemsChildForCatchingClick.length; i++) {
          if (itemsChildForCatchingClick[i] == clickedModel.object) {
            buttonDiv.style.display = 'block';
            focusCall(i);
            updateRemoteState({ flag: REMOTE_ACTION.FOCUS, focusItem: i });
          }
        }
      }
    }
    if (touchType.touchDown == type && screen === screenType.GamePlay) {
      itemsArray[0].vy = 0.5;
      itemsArray[0].position.y += itemsArray[0].vy;
    }
  };
  const onwheelEvent = (event) => {
    if (isTweening || !focusedModel) return;
    let scl = focusedModel.scale.x;
    if (event.deltaY > 0) {
      if (scl < maxScale) scl += incScale;
    } else {
      if (scl > minScale) scl -= incScale;
    }
    focusedModel.scale.set(scl, scl, scl);
  };
  const gameLogic = () => {
    if (hoop.length === 0 || itemsArray.length === 0 || !itemsArray[0]) return;
    hoop.forEach((element) => {
      element.obj.position.x += element.spd;
      element.obj.rotation.z -= 0.1;
      if (
        CircRectsOverlap(
          itemsArray[0].position.x,
          itemsArray[0].position.y,
          0.6,
          1.0,
          element.obj.position.x,
          element.obj.position.y,
          1.0
        )
      ) {
        gameOver();
      }
    });
    if (itemsArray[0].position.y > 0) {
      itemsArray[0].position.y += itemsArray[0].vy;
      itemsArray[0].vy -= 0.03;
      if (itemsArray[0].position.y < 0) {
        itemsArray[0].position.y = 0;
      }
    }
    if (counter % 20 == 0) {
      let isRingInGame = false;
      hoop.forEach((element) => {
        if (element.obj.position.x < 0) {
          isRingInGame = true;
        }
      });
      if (!isRingInGame) {
        const spd = Math.random() * 0.1 + 0.1;
        for (let i = 0, j = 0; i < hoop.length && j < 2; i++) {
          if (hoop[i].obj.position.x >= 20) {
            hoop[i].obj.position.set(-15 - j * 10, -0.81, 0);
            hoop[i].spd = spd;
            j++;
          }
        }
      }
    }
    counter++;
  };
  const remoteObjectUpdate = () => {
    if (loadingCount > 0) {
      loadingCount++;
    } else {
      return;
    }
    if (!viewOnly && loadingCount % 5 == 0 && focusedModel && !isTweening) {
      updateRemoteObjectControlForModel();
    }
    if (
      viewOnly &&
      circusRemoteState &&
      circusRemoteState?.flag &&
      itemsArray[0] &&
      loadingCount > 10
    ) {
      if (circusRemoteState.flag == REMOTE_ACTION.FOCUS) {
        focusCall(circusRemoteState.focusItem);
      }
      if (circusRemoteState.flag == REMOTE_ACTION.UN_FOCUS) {
        unFoucosCall();
      }
      if (
        circusRemoteState.flag == REMOTE_ACTION.MOUSE_CONTROL &&
        !isTweening
      ) {
        if(!focusedModel){
          focusedModel = itemsArray[0];
        }
        if (focusedModel && circusRemoteState.hoopsPosition) {
          focusedModel.position.set(
            circusRemoteState.position[0],
            circusRemoteState.position[1],
            circusRemoteState.position[2]
          );
          focusedModel.scale.set(
            circusRemoteState.scale[0],
            circusRemoteState.scale[1],
            circusRemoteState.scale[2]
          );
          focusedModel.rotation.set(
            circusRemoteState.rotation[0],
            circusRemoteState.rotation[1],
            circusRemoteState.rotation[2]
          );  
          hoop.forEach((element, i) => {
            if (circusRemoteState.hoopsPosition[i])
              element.obj.position.x = circusRemoteState.hoopsPosition[i][0];
            element.obj.rotation.z -= 0.1;
            element.obj.visible = true;
          });
        }
      }
      circusRemoteState.flag = undefined;
      circusRemoteState = undefined;
    }
  };
  const animate = () => {
    if (renderer == undefined || scene == undefined) {
      return;
    }
    remoteObjectUpdate();

    TWEEN.update();
    renderer.render(scene, camera);
    requestAnimationFrame(animate);
    if (screen === screenType.GamePlay && !viewOnly) gameLogic();
  };
  const loadEnvironment = () => {
    const textureLoader = new THREE.TextureLoader(loadingManager);
    textureRing = textureLoader.load(ringImg);
    textureRing.encoding = THREE.sRGBEncoding;
    textureRing.wrapS = THREE.RepeatWrapping;
    textureRing.wrapT = THREE.RepeatWrapping;
    textureRing.repeat.set(16, 1);

    const texture = textureLoader.load(circusImg);
    texture.encoding = THREE.sRGBEncoding;
    const mashAtrium = new THREE.Mesh(
      new THREE.PlaneGeometry(125, 93),
      new THREE.MeshBasicMaterial({ map: texture })
    );
    mashAtrium.position.set(0, 2, -60);
    scene.add(mashAtrium);

    const loader = new RGBELoader();
    loader.setDataType(THREE.UnsignedByteType);
    const modelBackgroundPath = interiorHDR;
    modelBackgroundPath &&
      loader.load(
        modelBackgroundPath,
        (texture) => {
          if (renderer && scene) {
            let pmremGenerator = new THREE.PMREMGenerator(renderer);
            pmremGenerator.compileEquirectangularShader();
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;
            pmremGenerator = undefined;
            scene.environment = envMap;
          }
        },
        undefined,
        undefined
      );
  };
  const cleanMaterial = (material) => {
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }
    material.dispose();
    material = undefined;
  };
  const cleanUp = () => {
    itemsArray.length = 0;
    itemsChildForCatchingClick.length = 0;
    if (scene != undefined) {
      scene.traverse((object) => {
        if (!object['isMesh']) return;
        object['geometry'].dispose();
        if (object['material'].isMaterial) {
          cleanMaterial(object['material']);
        } else {
          // an array of materials
          for (const material of object['material']) cleanMaterial(material);
        }
        object['geometry'] = undefined;
        object = undefined;
      });
      scene.children.forEach((model) => {
        scene.remove(model);
      });
    }

    if (renderer != undefined) {
      renderer.dispose();
      renderer && renderer.renderLists.dispose();
    }
    if (controls) {
      controls.disableVerticalRotation();
      controls.disableHorizontalRotation();
      controls.disableZoom();
    }
    document.removeEventListener('touchstart', eventDown);
    document.removeEventListener('touchmove', eventMove);
    document.removeEventListener('touchend', eventUp);
    document.removeEventListener('mousedown', eventDown);
    document.removeEventListener('mousemove', eventMove);
    document.removeEventListener('mouseup', eventUp);
    document.removeEventListener('wheel', onwheelEvent);
    window.removeEventListener('resize', onWindowResize);
    controls = undefined;
    scene = undefined;
    renderer = undefined;
    camera = undefined;
  };
  React.useEffect(() => {
    if (remoteState?.flag) {
      circusRemoteState = remoteState;
    }
  }, [remoteState]);

  React.useEffect(() => {
    const canvas = document.querySelector('#c') as HTMLCanvasElement;
    camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      200
    );
    renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    renderer.outputEncoding = THREE.sRGBEncoding;
    camera.position.set(0, 0, 30);
    const color = 0xeeeeee;
    const ambientLight = new THREE.AmbientLight(color, 0.21);
    ambientLight.name = 'ambient_light';
    const sixtyDegreeLight = new THREE.DirectionalLight(color, 0.21);
    sixtyDegreeLight.position.set(1.5, 1.2, -0.5);
    sixtyDegreeLight.name = 'main_light';
    scene.add(sixtyDegreeLight);
    buttonDiv = document.getElementById('centertxt');
    buttonDiv.onclick = playGame;
    loadThreeJsModulesAsync().then(() => {
      loadEnvironment();
      loadObject(clown, 0);
      const geometry = new THREE.TorusGeometry(0.8, 0.05, 16, 16);
      for (let i = 0; i < 5; i++) {
        loadHoop(geometry, textureRing);
      }
      if (!viewOnly) {
        addEventListeners();
      }
      animate();
    });

    return () => {
      // everything here will be called on unmount
      // clean up
      cleanUp();
    };
  }, []);

  return (
    <div>
      <canvas id="c" ref={canvasRef}></canvas>
      <span id="centertxt">
        <button id="playbut" className="btn btn-outline-light btn-lg with-shadow">PLAY</button>
      </span>
      <style jsx>
        {`
          #c {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
          :global(.in-meeting.MDLandscape) #c {
            left: ${MDLandscapeNormalSpec.contentArea.left / 2}px;
          }
          :global(.in-meeting.SMLandscape) #c {
            left: ${SMLandscapeNormalSpec.contentArea.left / 2}px;
          }
          #centertxt {
            position: fixed;
            left: 50%;
            bottom: 100px;
            transform: translateX(-50%);
            margin: 0 auto;
            display: none;
          }

          :global(.in-meeting.MDLandscape) #centertxt {
            margin-left: ${MDLandscapeNormalSpec.contentArea.left / 2}px;
          }
          :global(.in-meeting.SMLandscape) #centertxt {
            margin-left: ${SMLandscapeNormalSpec.contentArea.left / 2}px;
          }

          :global(.in-meeting.MDPortrait) #centertxt {
            margin-bottom: ${MDPortraitNormalSpec.contentArea.bottom}px;
          }
          :global(.in-meeting.SMPortrait) #centertxt {
            margin-bottom: ${SMPortraitNormalSpec.contentArea.bottom}px;
          }
          .btn{
            border-radius:10rem;
            background:#222;
          }
          .btn:hover{
            background:#fff;
          }
        `}
      </style>
    </div>
  );
};

export default Circus;
