import { useSelector } from 'react-redux';
import { IMainState } from '../../interfaces';
import { ChopardAnimation } from '../../interfaces/chopard';

export const useRemoteChopardAnimationState = (
  animation: ChopardAnimation
) => {
  const remoteState = useSelector(
    (state: IMainState) =>
      (state.clientState.chopard?.remoteAnimation || {})[animation]
  );

  return remoteState;
};

export const useChopardState = () =>
  useSelector((state: IMainState) => state.clientState.chopard || {});

